import React from 'react'

import {
  Text,
  Flex,
  Box,
  Image,
  Card,
} from 'rebass'

import Layout from '../../components/layout'
import Container from '../../components/container'
import BottomHero from '../../components/bottomHero'

import FreeTrialModal from '../../components/freeTrialModal'

import monthlyPlan from '../../images/monthly_plan.jpg'

const CustomReversityPlanPage = () => (
  <Layout>
    <Container>
      {/* Hero */}
      <Flex flexWrap='wrap' p={3} mx={-2}>
        <Box mx='auto'>
          <Text textAlign='center' py={2} fontWeight='bold' fontSize={5}>Custom Reversity Plan</Text>
        </Box>
      </Flex>

      <Flex flexWrap='wrap' p={3} mx={-2}>
        <Box width={[1, 1/2]} p={2}>
          <Image src={monthlyPlan} alt='Reversity monthly plan' />
        </Box>

        <Box width={[1, 1/2]} p={2}>
          <Text>Work one-on-one with our founder, Dr. Harris, to develop a plan to rapidly normalize your blood sugars and achieve your health goals.</Text>

          <Box py={3}>
            <Text color='gray' fontWeight='bold' py={1}>$199.99/month after 7-day free trial</Text>
            <FreeTrialModal />
          </Box>
        </Box>
      </Flex>
    </Container>

    <Box bg='lightGray' py={5}>
      <Container>
        <Flex flexWrap='wrap' p={3} mx={-2}>
          <Box p={2} width={1}>
            <Card
              p={3}
              variant='primary'
              borderRadius={5}
              color='gray'
              bg='white'>
              Extensive, on-going consultation, through secure text messaging or email, with our founder, Dr. Harris. As your personal coach and guide, Dr. Harris will be available to answer your questions and concerns at every step of the program.
            </Card>
          </Box>
          <Box p={2} width={1}>
            <Card
              p={3}
              variant='primary'
              borderRadius={5}
              color='gray'
              bg='white'>
              Detailed analysis of your baseline diet. Use your phone to snap pictures of your meals and snacks, and we will do the rest on our end. We will identify your current macronutrient ratio, omega 6:3 ratio, and provide a detailed qualitative assessment of the foods in your typical diet. In addition to photos, we will use web/mobile surveys to understand your dietary habits, likes, and dislikes.
            </Card>
          </Box>
          <Box p={2} width={1}>
            <Card
              p={3}
              variant='primary'
              borderRadius={5}
              color='gray'
              bg='white'>
              Personalized recommendations. We will provide specific nutritional recommendations to meet your health goals, and translate those recommendations into a detailed set of action plans. This process will enable you to gradually shift your way of eating in a way that is sustainable (and enjoyable!) after the program ends.
            </Card>
          </Box>
          <Box p={2} width={1}>
            <Card
              p={3}
              variant='primary'
              borderRadius={5}
              color='gray'
              bg='white'>
              Weekly check-ins. In addition to responding to questions as they arise, Dr. Harris will personally check in with you on a weekly basis throughout the program. These check-ins are critical to determine which elements of the program are most effective, and enables us to tailor your action plans accordingly for the next week.
            </Card>
          </Box>
          <Box p={2} width={1}>
            <Card
              p={3}
              variant='primary'
              borderRadius={5}
              color='gray'
              bg='white'>
              Educational and motivational resources to support your journey. We realize that changing your way of eating is an enormous challenge, and we are determined to provide the tools and information to help you succeed. Every few days we will send relevant videos, lectures, and articles, hand-picked by our team of experts to support your health goals and current action plans.
            </Card>
          </Box>
          <Box py={2} mx='auto'>
            <FreeTrialModal />
          </Box>
        </Flex>
      </Container>
    </Box>
    <BottomHero />
  </Layout>
)

export default CustomReversityPlanPage
